import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconTacos = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/bacontacos_large.jpg';

const RecipePage = () => (
    <Layout metaTitle="Southern Fried Bacon Tacos Recipe"
        metaDescription="What's better than tacos? Tacos filled with bacon. Give your Taco Tuesday a twist with our southern fried tacos with Wright Brand Bacon. Try today!"
        metaKeywords="taco recipe, fried tacos"
    >
        <meta property="og:image" content={imageBaconTacos} />
        <link rel="image_src" href={imageBaconTacos} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconTacos} class='img-responsive' alt='Southern Fried Bacon Tacos' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Southern Fried Bacon Tacos</h1>

                            <div class="recipe-content">
                                <p >We're not sure if our great grandfathers observed &ldquo;Taco Tuesday," but we're confident this is the way they would have celebrated... with a generous helping of hand-trimmed, thick-cut hickory smoked bacon, like the kind they would have picked up at their neighborhood butcher. Then, being well acquainted with layering flavors, they would have balanced out the bacon's depth of smoke and salt with the natural sweetness of corn and peaches. Only declaring these tacos finished after topping them with basil and cherry, keeping things fresh, light and good-lookin'... just like them.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        45&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4-6 tacos</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom">Strips</span> Wright&reg; Brand Hickory Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Cups</span> corn meal
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/2 <span class="uom">Cup</span> corn starch
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom"></span> eggs
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> milk
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Cups</span> canola or vegetable oil
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> ripe peach
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> fennel bulb
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Cup</span> grape tomatoes
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> hand torn basil leaves, packed
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> fennel frond
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Teaspoon</span> fresh ground pepper
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Teaspoon</span> kosher salt
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> light extra virgin olive oil
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom">Cup</span> crumbled goat cheese
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> flour tortillas
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. To give your “Taco Tuesdays” a new spin start by preheating a conventional oven at 400°F. Line a baking pan with aluminum foil and place strips of bacon onto the pan. Place the pan in the oven and cook for 18-20 minutes or until crisp. Remove the bacon and place it onto a stack of paper towels, removing any excess grease. When the bacon has cooled enough to handle (patience) cut each slice in half and set aside for frying.
                </p>
                            <p>
                                2. While the bacon is cooking inside, head out and start the outdoor grill with a heavy coat of non-stick cooking spray. Turn the heat on the grill to medium-high. Then clean and slice the peach into quarters after removing the pit in the middle. Place the segments of peach onto the grill for 3-5 minutes or until tender with a nice charring. Lower the temperature of the grill to low, then remove the peaches from the grill and allow them to cool. Then, slice them into small cubes and reserve in a mixing bowl.
                </p>
                            <p>
                                3. Next, clean the fennel bulb by cutting off the green frond on the top of the bulb. (Reserve a quarter cup of this frond for later use). Remove any discolored spots on the outside of the bulb and rinse under cold water to clean off any unwanted particulates. Using a sharp knife or mandolin carefully slice thin panels of the bulb starting on the side opposite of the core. Once the bulb is all sliced reserve it in a container of cold water.
                </p>
                            <p>
                                4. Then clean and slice the tomatoes in half and add to the mixing bowl with the cubed peaches. Toss all the remaining ingredients for the salad in the same mixing bowl with fennel and refrigerate.
                </p>
                            <p>
                                5. Now that your bacon is finished and your salad is chilling, fry up that bacon. Start by heating the oil in a skillet over medium heat, this will take approximately 8-10 minutes to get to temperature. While you’re waiting, get your bacon ready. You’ll be using a two bowl system; one bowl with corn meal and starch and the other bowl with eggs and milk whisked together. Take each bacon half and dunk carefully into liquid mixture, then dust into the dry mixture and reserve onto a clean baking pan. Repeat this step with all remaining bacon strips until all of the bacon is coated. Carefully place the bacon in the skillet and cook for 2-3 minutes until crisp. Try not to over crowd the skillet with all the bacon, do this step in waves and after each wave place the bacon onto clean paper towels allowing the grease to drain off.
                </p>
                            <p>
                                6. Now that everything you need to cook inside is complete take the party outside. With the outdoor grill still warm give your tortillas a toasty warm-up. Just let the tortillas sit on the grill for 1-2 minutes on each side. Then reserve them between a clean damp towel. This will help keep the tortillas moist, pliable, and delicious.
                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
